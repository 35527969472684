
import {Component, Prop, Vue} from 'vue-property-decorator';
import {password, required} from '@/utils/validationRules';
import IPasswordForm from '@/types/IPasswordForm';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';

@Component({name: 'ResetPasswordForm'})
export default class ResetPasswordForm extends Vue {
  @Prop()
  onSubmit!: Function;

  @Prop()
  hideLoginButton!: boolean;

  form: IPasswordForm = {
    newPassword: '',
    repeatedPassword: '',
  };
  rules: object = {
    newPassword: [required, password],
    repeatedPassword: [required, this.confirmPassword],
  };
  loading: boolean = false;

  confirmPassword(value: string) {
    return value === this.form.newPassword || this.$t('SYSTEM_ENTERED_PASSWORDS_MUST_BE_THE_SAME');
  }

  async submit() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      this.loading = true;
      try {
        await this.onSubmit(this.form);
        notify(NotificationTypes.success, this.$t('SYSTEM_PASSWORD_HAS_BEEN_CHANGED'));
      } catch (e) {
        resolveError(e, 'restore_password');
      } finally {
        this.loading = false;
      }
    }
  }
}
