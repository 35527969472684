
import {Component, Vue} from 'vue-property-decorator';
import ProfileForm from '@/components/Profile/ProfileForm.vue';
import ResetPasswordForm from '@/components/Profile/ResetPasswordForm.vue';
import AcceptedTerms from '@/components/Profile/AcceptedTerms.vue';
import AuthService from '@/services/AuthService';
import IPasswordForm from '@/types/IPasswordForm';
import {ILanguage} from '@/types/TranslationTypes';
import {namespace} from 'vuex-class';
import ITerm from '@/types/ITerms';
import UserService from '@/services/UserService';
import {resolveError} from '@/utils/notifications';

const Translations = namespace('translations');

@Component({
  name: 'Profile',
  components: {
    ResetPasswordForm,
    ProfileForm,
    AcceptedTerms,
  },
})
export default class Profile extends Vue {

  @Translations.Getter
  getCurrentLanguage!: string;

  @Translations.Getter
  languagesList!: ILanguage[];

  terms: ITerm[] = [];

  created() {
    this.getAcceptedTerms();
  }

  async getAcceptedTerms() {
    const language = this.prepareLanguage();
    try {
      this.terms = await UserService.getAcceptedTerms(language.id || 0);
    } catch (e) {
      resolveError(e, 'fetch_data');
    }
  }

  async changePassword(data: IPasswordForm) {
    try {
      await AuthService.updatePassword(data);
      await AuthService.refreshToken();
    } catch (e) {
      throw e;
    }
  }

  prepareLanguage(): ILanguage {
    return this.languagesList
        .find((element) => element.shortcut === this.getCurrentLanguage) || this.languagesList[0];

  }
}
